@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('/fonts/MPLUSRounded1c-Regular.woff2') format('woff2');
  font-display: swap;
}

img,
svg {
  display: inline-block !important;
  /* important added to resolve conflicts with external css
    eg. tinymce skin css, katex etc
  */
  vertical-align: inherit;
  /* override tailwind defaults of block everything */
}

body {
  background-color: white;
}

/*html 16px sets ref for all em, rem calculations*/

html {
  font-size: 16px !important;
}

* {
  font-family: 'M PLUS Rounded 1c', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}

iframe {
  max-width: 100%; /*for all tinymce embedded iframes, anyways a safe condition*/
}

.ylurnTinymceImage {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

#nprogress .bar {
  z-index: 1300 !important; /*more than material-ui app bars*/
}

a {
  text-decoration: none !important;
  color: #2563eb;
}

hr {
  color: #e5e7eb;
  border-top: 1px solid #e5e7eb;
  margin: 16px 0;
}

fieldset {
  padding: 0;
  border: none;
}

p {
  margin: 0;
}

.muiModalZindexClass {
  z-index: 1300;
}

/*video js hide captions button*/
.vjs-subs-caps-button {
  display: none;
}

.ylurn-no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ylurn-no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
